import React from 'react';

import './FooterOverlay.css';

const FooterOverlay = () => (
  <div className='app__footeroverlay'>
    <div className='app__footeroverlay-black'/>
    <div className="app__footeroverlay-img app__bg">

    </div>
  </div>
);

export default FooterOverlay;
